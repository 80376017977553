html
{
	overflow: -moz-scrollbars-vertical;
}

body
{
	margin: 0px 0px 0px 0px;
	background: url('./assets/images/background.gif');
	background-repeat: repeat-x;
	font-family: 'Trebuchet MS';
	font-size: 13px;
}

h1 {
	margin-top: 0;
	font-size: 20px;
	font-weight: bold;
	color: #73A462;
}

p { color: #000000; }

b { color: #73A462; }

a:link, a:visited { color:#73A462; }