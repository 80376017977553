#header {
    height: 120px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#container
{
	display: table;
	width: 850px;
	min-height: 590px;
	margin: auto;
	background-color: #FFFFFF;
	padding: 10px 10px 10px 10px;
}

#menu
{
	width:190px;
	height:300px;
	float:left;
}

#menuheader
{
	width: 190px;
	height: 32px;
	background-image: url('./assets/images/menubg.gif');
}

#menutitle
{
	font-family: Trebuchet MS;
	font-size: 20px;
	font-weight: bold;
	color: #FFFFFF;
	padding-top: 3px;
	padding-left: 10px;
	height: 32px;
}

.menuitem {
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-left: solid #699A58 1px;
    border-right: solid #699A58 1px;
	font-size: 14px;
	color: #000 !important;
	text-decoration: none;
}

.menuitem:hover {
	background-color: #DEFFD5;
	font-weight: bold;
}

.menuitem + .menuitem {
    border-top: 1px solid #E3E3E3;
}

.menuitem:last-child {
    border-bottom: solid #699A58 1px;
}

.block {
    width: 4px;
    height: 4px;
    margin: 0 10px;
}

#content
{
	width:640px;
	min-height:450px;
	float:right;
}